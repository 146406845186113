@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin: 0;
}

p {
  margin: 0;
}

.no-mp {
  margin: 0 !important;
  padding: 0 !important;
}

.rmv-mb {
  margin-bottom: 0 !important;
}

.normal-text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 0 40px 60px 40px;
  margin-bottom: 0;
  text-decoration: none;
  color: #262626;
}

.normal-text:hover {
  color: #262626;
  text-decoration: none;
}

.full-width {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

/* buttons */

.button-default {
  background-color: #2a9d8f;
  color: #262626;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 16px 26px;
  text-decoration: none;
  border-radius: 5px;
  margin: 0 15px;
}

.button-default:hover {
  text-decoration: none;
  color: #262626;
  background-color: #188779;
}

.button-default-purple {
  background-color: #803bca;
  color: #fff;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 16px 26px;
  text-decoration: none;
  border-radius: 5px;
  margin: 0 15px;
}

.button-default-purple:hover {
  text-decoration: none;
  color: #fff;
  background-color: #6522ac;
}

.button-default-small {
  background-color: #2a9d8f;
  color: #262626;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 10px 16px;
  text-decoration: none;
  border-radius: 5px;
  margin: 50px 15px;
}

.button-default-small:hover {
  text-decoration: none;
  color: #262626;
  background-color: #188779;
}

.button-default-small-purple {
  background-color: #803bca;
  color: #fff;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 10px 16px;
  text-decoration: none;
  border-radius: 5px;
  margin: 50px 15px;
}

.button-default-small-purple:hover {
  text-decoration: none;
  color: #fff;
  background-color: #6522ac;
}

.button-default-small-red {
  background-color: #dc3545;
  color: #fff;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 10px 16px;
  text-decoration: none;
  border-radius: 5px;
  margin: 50px 15px;
}

.button-default-small-red:hover {
  text-decoration: none;
  color: #fff;
  background-color: #bd1b2b;
}
/* Navigation */

.navigation {
  height: 130px;
  width: 100%;
  background-color: #fff;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
}

.logo {
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  color: #262626;
  text-decoration: none;
  margin-left: 60px;
  margin-bottom: 0;
}

.logo:hover {
  text-decoration: none;
  color: #262626;
}

.nav-item {
  font-family: "Bebas Neue", cursive;
  font-size: 30px;
  color: #262626;
  margin-right: 60px;
  margin-bottom: 0;
  float: left;
  cursor: pointer;
}

.nav-item:hover {
  text-decoration-color: #55c2b5;
  color: #262626;
}

@media screen and (max-width: 768px) {
  .navigation {
    height: 100px;
  }

  .logo {
    font-size: 25px;
    margin-left: 30px;
  }

  .nav-item {
    margin-right: 20px;
    font-size: 17px;
  }

  .about-us-link {
    display: none;
  }
}


/* fin de navigation */

/* footer */

.footer {
  height: 255px;
  width: 100%;
  background-color: #262626;
  display: flex;
  padding: 0;
  margin: 110px 0 0 0;
  align-items: center;
  justify-content: flex-start;
}

.logo-footer {
  font-family: "Bebas Neue", cursive;
  font-size: 45px;
  color: #fff;
  text-decoration: none;
  margin-left: 160px;
  margin-bottom: 0;
  position: relative;
}

.logo-footer:hover {
  text-decoration: none;
  color: #fff;
}

/* .logo-footer::after {
  content: "";
  position: absolute;
  left: 140%;
  top: 45%;
  height: 1px;
  width: 200px;
  background-color: #ab6eec;
} */

.nav-item-footer {
  font-family: "Bebas Neue", cursive;
  font-size: 32px;
  color: #fff;
  margin-right: 60px;
  margin-bottom: 0;
  float: left;
}

.nav-item-footer:hover {
  color: #ab6eec;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .logo-footer {
    font-size: 30px;
    margin-left: 60px;
  }

  .nav-item-footer {
    font-size: 25px;
    margin-right: 30px;
  }

  .footer-links-wrapper {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .logo-footer {
    margin-left: 0px;
  }

  .nav-item-footer {
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .footer-links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* fin de footer */

/* Home page */

.opacity-20-dark {
  position: relative;
  background-color: #262626;
  width: 100%;
  height: auto;
}

.titre-image {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(38, 38, 38, 0.2);
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-home-1 {
  display: flex;
  justify-content: center;
  margin-right: 0;
  padding-bottom: 110px;
  background-image: url(./images/bgHome1.svg);
  background-repeat: no-repeat;
  background-position: bottom -15px left -15px;
}

.card-actu {
  width: 400px;
  border-radius: 10px !important;
  margin: 0 2%;
  padding: 0;
}

.titre-actu {
  font-family: "Bebas Neue", cursive;
  color: #262626;
  font-size: 32px;
  text-align: center;
  position: relative;
  padding-top: 40px;
}

.titre-actu::after {
  content: "";
  bottom: 0;
  left: auto;
  position: absolute;
  height: 1px;
  width: 50px;
  background-color: #803bca;
  display: block;
}

.img-paralax {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  background-image: url(./images/paralax.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-action {
  width: 640px;
  margin: 0 0 0 10%;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding-top: 20px;
  border-radius: 10px !important;
}

.card-info {
  width: 320px;
  border-radius: 10px !important;
  margin: 0 2% 2% 2%;
  border: none !important;
  background-color: #fff;
}

.bg-home-2 {
  background-image: url(./images/bgHome2.svg);
  background-repeat: no-repeat;
  background-position: -15px -15px;
}

/* Fin de homepage */

/* Titre H1 */



.titre-h1-page {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  margin-top: 10px;
  margin-bottom: 80px;
  margin-left: 10%;
  position: relative;
}

@media all and (max-width: 1024px) {
  .titre-h1-page {
    font-size: 50px;
  }
}

@media all and (max-width: 768px) {
  .title-wrapper {
    flex-direction: column;
  }
}


.titre-h1-page::after {
  content: "";
  bottom: 0;
  left: 0;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

/* Titre H2 */

.titre-h2 {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  margin-top: 4%;
  margin-bottom: 5%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.titre-h2::after {
  content: "";
  bottom: -5px;
  left: 50%;
  margin-left: -68px;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

.titre-h2-page {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  margin: 4% 0 5% 10%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.titre-h2-page::after {
  content: "";
  bottom: -5px;
  left: 0;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

/* Titre H3 */

.titre-h3 {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 38px;
  margin-top: 4%;
  margin-bottom: 5%;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0 20%;
}

.titre-h3::after {
  content: "";
  bottom: -5px;
  left: 50%;
  margin-left: -68px;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

/* Titre H1 BIS */

.titre-h1-bis {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  margin-top: 8%;
  position: relative;
}

.titre-h1-bis::after {
  content: "";
  bottom: 0;
  left: 50%;
  margin-left: -68px;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #803bca;
  display: block;
}

/* Titre H2 BIS */

.titre-h2-bis {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  margin-top: 4%;
  margin-bottom: 5%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.titre-h2-bis::after {
  content: "";
  bottom: -5px;
  left: 38%;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #803bca;
  display: block;
}

.bg-both-accueil {
  display: flex;
  height: 560px;
}

.bg-single-accueil {
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-main-text {
  display: flex;
}

@media all and (max-width: 768px) {
  .bg-both-accueil {
    flex-direction: column;
  }

  .bg-single-accueil {
    width: 100%;
  }

  .home-main-text {
    display: flex;
    flex-direction: column;
  }
}

.titre-image-home-asso {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  background: rgba(38, 38, 38, 0.2);
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.titre-image-home-asso:hover {
  text-decoration: none;
}

.titre-image-home-bene {
  /* position: absolute; */
  /* top: 0; */
  /* left: 50%; */
  background: rgba(38, 38, 38, 0.2);
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.titre-image-home-bene:hover {
  text-decoration: none;
}

.titre-h1 {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  margin-top: 8%;
  position: relative;
  text-align: center;
}

.titre-h1::after {
  content: "";
  bottom: 0;
  left: 100%;
  /* margin-left: -68px; */
  /* position: absolute; */
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

.titre-h1-violet {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  margin-top: 8%;
  position: relative;
  text-align: center;
}

.titre-h1-violet::after {
  content: "";
  bottom: 0;
  left: 100%;
  /* margin-left: -68px; */
  /* position: absolute; */
  height: 2px;
  width: 136px;
  background-color: #803bca;
  display: block;
}

/* Fullscreen images */

.image-header {
  width: 100%;
  height: 70vh;
  opacity: 80%;
  transition: transform 0.5s; /* Animation */
}

.ratio-ok {
  width: 100%;
  height: auto;
}

.hover-image:hover {
  transform: scale(1.2);
}

/* Card Benevoles */

.card-type-2 {
  width: 430px;
  margin: 0 26px 52px 26px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background-color: #fff;
}
.card-type-2 .wrapper {
  display: flex;
}

.card-image {
  width: 80%;
  margin: 25px 20px 20px 25px;
  height: auto;
}

@media all and (max-width: 768px) {
  .card-type-2 {
    width: 100%;
    margin: 0;
  }

  .card-type-2 .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .card-type-2 .desc {
    justify-content: center;
  }
}

.titre-card-type-2 {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 26px;
  margin-top: 25px;
  position: relative;
  margin-bottom: 0;
}

.titre-card-type-2::after {
  content: "";
  bottom: 3px;
  left: 0;
  position: absolute;
  height: 1px;
  width: 40px;
  background-color: #55c2b5;
  display: block;
}

.sous-titre-card-type-2 {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #262626;
  text-decoration: underline;
}

.competences-liste {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 13px;
  padding-left: 9px;
  position: relative;
}

.competences-liste::before {
  content: "";
  bottom: 7px;
  border-radius: 50%;
  left: 0;
  position: absolute;
  height: 4px;
  width: 4px;
  background-color: #803bca;
  display: block;
}

.text-card {
  margin: 0 25px 25px 25px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #262626;
}

.card-project {
  display: flex;
  justify-content: center;
  padding: 0 50px 0 50px;
  margin-bottom: 0px;
  background-image: url(./images/bgHomeBene.svg);
  background-repeat: no-repeat;
  background-position: top 0 left -15px;
}

.titre-autre {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 38px;
  margin-top: 4%;
  margin-bottom: 5%;
  display: inline-block;
  text-align: center;
  padding: 0 20%;
}

.card-type-3 {
  width: 70%;
  margin: 0 30px 40px 100px;
  background-color: #fff;
}

.profile-image {
  width: 141px;
  height: 141px;
  margin: 25px 20px 20px 25px;
  border-radius: 50%;
}

/* formulaire */

.formulaire-default {
  margin-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 30%;
}

@media screen and (max-width: 1024px) {
  .formulaire-default {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .formulaire-default {
    width: 80%;
  }
}

.formulaire-label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #262626;
  font-weight: 400;
}

.formulaire-label::after {
  content: "*";
  color: #dc3545;
  font-weight: 400;
  margin-left: 2px;
}

.facultatif::after {
  content: "";
}

/* inscription et connection */

.bg-inscription {
  background-image: url(./images/bgInscriptionBene.svg);
  background-repeat: no-repeat;
  background-position: top 130px right 0px;
}

.bg-inscription-formulaire {
  background-image: url(./images/bgInscription.svg);
  background-repeat: no-repeat;
  background-position: top 115px right -15px;
}

/* Dashboard */

.card-dashboard {
  /* width: 80%; */
  height: auto;
  margin-left: 10%;
  display: flex;
  align-items: center;
}

.image-dashboard {
  border-radius: 10px;
  width: 90%;
  margin: 0 30px 0 0;
  height: auto;
}

.image-dashboard-profil {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 30px 0 0;
}

.dashboard-content {
  width: 20%;
}

@media screen and (max-width: 1024px) {
  .card-dashboard {
    flex-direction: column;
    align-items: flex-start;
  }

  .image-dashboard {
    width: 100%;
    max-width: 400px;
  }

  .dashboard-content {
    width: 100%;
  }
}

.text-dashboard {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-top: 5px;
  padding-right: 50px;
  margin-bottom: 0;
  text-align: left;
  height: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-project-bank {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-top: 5px;
  padding-right: 0px;
  margin-bottom: 0;
  text-align: left;
  margin-right: 20px;
}

.text-dashboard-profil {
  width: 70%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 40px 0;
  padding-right: 50px;
  margin-bottom: 0;
  text-align: left;
}

.bg-profil {
  background-image: url(./images/bgProfil.svg);
  background-repeat: no-repeat;
  background-position: top 115px right -15px;
}

/* Banque de projet */

.card-skill-bank {
  width: 80%;
  height: auto;
  margin-left: 10%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
}

.image-skill-bank {
  border-radius: 10px;
  width: 90%;
  height: auto;
}

.skill-bank-content {
  width: 30%;
  padding: 0 0 0 25px;
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-skill-bank {
  background-image: url(./images/bgBanqueProjet.svg);
  background-repeat: no-repeat;
  background-position: top 115px left -15px;
}

.cardbox-project-bank {
  height: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  .card-skill-bank {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-skill-bank > div {
    width: 100% !important;
  }

  .image-skill-bank {
    width: 100% !important;
  }

  .skill-bank-content {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
}


/* Detail projet */

.image-projet-detail {
  width: 35%;
  height: auto;
  margin-left: 10%;
  margin-top: 90px;
  border-radius: 10px;
}

@media all and (max-width: 768px) {
  .image-projet-detail {
    width: 70%;
  }
}

.text-projet-detail {
  width: 75%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-left: 10%;
  padding-right: 50px;
  margin-bottom: 0;
  text-align: left;
}

.card-projet-detail {
  width: 80%;
  height: auto;
  margin-left: 10%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 50px 0 50px 71px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
}

@media all and (max-width: 768px) {
  .card-projet-detail {
    margin-left: 20px;
    width: calc(100% - 40px);
    padding: 20px;
  }
}

.titre-compétences {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

.titre-compétences::after {
  content: "";
  bottom: -5px;
  left: 0;
  position: absolute;
  height: 2px;
  width: 136px;
  background-color: #55c2b5;
  display: block;
}

.texte-competences-liste {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 25px;
  padding-left: 16px;
  position: relative;
}

.texte-competences-liste::before {
  content: "";
  bottom: 50%;
  border-radius: 50%;
  margin-bottom: -5px;
  left: 0;
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #803bca;
  display: block;
}

.bg-projet-detail {
  background-image: url(./images/bgProjet.svg);
  background-repeat: no-repeat;
  background-position: top 115px left -15px;
}

/* Page edition de profil */

.formulaire-default-profil {
  margin-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 52%;
}

.liste-de-competence-profil {
  width: 52%;
  background-color: #55c2b5;
  height: auto;
  padding: 18px 30px;
  margin-left: 10%;
  border-radius: 10px;
  margin-top: 40px;
}

.text-competence-profil {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 0;
}

/* formulaire asso */

.bg-inscription-formulaire-asso {
  background-image: url(./images/bgInscriptionA.svg);
  background-repeat: no-repeat;
  background-position: top 115px right -15px;
}

/* dashboard asso */

.card-dashboard-asso {
  width: 50%;
  height: auto;
  margin-left: 10%;
  display: flex;
  align-items: center;
}

.image-dashboard-asso {
  border-radius: 50%;
  width: 175px;
  height: 175px;
  margin: 0 30px 0 0;
}

.dashboard-content-asso {
  width: 25%;
}

.bg-dashboard-asso {
  background-image: url(./images/bgDashboardAsso.svg);
  background-repeat: no-repeat;
  background-position: top 115px right -15px;
}

/* Projet asso */
.bg-projet-detail-asso {
  background-image: url(./images/bgProjetAsso.svg);
  background-repeat: no-repeat;
  background-position: top 115px left -15px;
}

.card-competence {
  display: flex;
  justify-content: center;
  padding: 0 50px 50px 50px;
  margin-bottom: 0px;
  overflow-y: scroll;
  max-height: 100vh;
}

.bg-home-asso {
  background-image: url(./images/bgSkillBank.svg);
  background-repeat: no-repeat;
  background-position: top 0 left -15px;
}

/* Skill Bank */

.bg-skill-bank-page {
  background-image: url(./images/bgSkillBankPage.svg);
  background-repeat: no-repeat;
  background-position: top 115px left -15px;
}

.text-home-info {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  margin: 20px 20% 30px 25px;
  padding-right: 50px;
  margin-bottom: 0;
  text-align: left;
}

.normal-text-home {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 0 40px 30px 40px;
  margin-bottom: 0;
  text-decoration: none;
  color: #262626;
}

.button-default-home {
  background-color: #2a9d8f;
  color: #262626;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 10px 28px;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 40px;
}

.button-default:hover {
  text-decoration: none;
  color: #262626;
  background-color: #188779;
}

.normal-text-home-oeuvre {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  padding: 40px 40px 30px 40px;
  margin-bottom: 0;
  text-decoration: none;
  color: #262626;
}

.titre-card-type-2-project {
  color: #262626;
  font-family: "Bebas Neue", cursive;
  font-size: 26px;
  line-height: 32px;
  margin-top: 25px;
  position: relative;
  margin-bottom: 0;
}



.titre-card-type-2::after {
  content: "";
  bottom: 3px;
  left: 0;
  position: absolute;
  height: 1px;
  width: 40px;
  background-color: #55c2b5;
  display: block;
}

.hamburger_hidden {
  /* display: none; */
  opacity: 0;
  transition: 1s;
  position: relative;
  bottom: 200px;
}

.hamburger_show {
  /* display: block; */
  opacity: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  position: relative;
  z-index: 1;
  transition: 0.85s;
  bottom: 0;
}

.hamburger_item {
  color: #262626;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}

.hamburger_item:hover {
  color: #262626;
  text-decoration: none;
}

.hamburger_text {
  margin: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.profil_container {
  position: relative;
  bottom: 110px;
  z-index: 0;
}

.no-project-container {
  margin-left: 200px;
}

@media all and (max-width: 1024px) {
  .no-project-container {
    margin-left: 10%;
  }

  .button-default {
    margin: 0;
  }

  .button-default-small {
    margin: 0;
  }

  .button-default-purple {
    margin: 0;
  }

  .button-default-small-purple {
    margin: 0;
  }
}

.input_container {
  display: flex;
  flex-direction: column;
}

.input {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  margin: 15px;
}

@media all and (max-width: 768px) {
  .input {
    width: calc(100% - 40px);
  }
}

.text_area {
  width: 600px;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  margin: 15px;
}

@media all and (max-width: 768px) {
  .text_area {
    width: calc(100% - 40px);
  }
}

.error_message {
  color: #bd1b2b;
}

.succes_message {
  color: #188779;
}

.link:hover {
  text-decoration: none;
}
.forgot-link:hover {
  color: #262626;
}

.volunteer_container {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: 80vw;
}
.applicant_container {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: 100vw;
}

.checkbox {
  margin-right: 20px;
}

.notification_pastilla {
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: red;
}

.sub-footer {
  height: 60px;
  background-color: #161616;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.sub-footer .links {
  display: flex;
  margin: 0;
  padding: 0;
}

.sub-footer .links li {
  display: flex;
  list-style: none;
  margin-left: 20px;
}

.sub-footer .links li a {
  color: white;
}


@media all and (max-width: 800px) {
  .sub-footer {
    flex-direction: column;
    padding: 30px;
    height: auto;
  }

  .sub-footer .links {
    flex-wrap: wrap;
    justify-content: center;
  }
}